import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['caption']

  cover(e) {
    e.preventDefault()
    this.captionTarget.classList.toggle('h-48')
  }
}
