// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['canvas']

  show(e) {
    e.preventDefault()
    this.canvasTarget.src = e.target.getAttribute('data-canvas-url')
    this.canvasTarget.alt = e.target.getAttribute('data-alt')
  }
}
