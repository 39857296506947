// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['box', 'inputfield']

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'
  }

  toggle() {
    this.boxTarget.classList.toggle(this.toggleClass)
    this.inputfieldTarget.focus()
  }

  hide(event) {
    if (
      this.element.contains(event.target) === false &&
      !this.boxTarget.classList.contains(this.toggleClass)
    ) {
      this.boxTarget.classList.add(this.toggleClass)
    }
  }
}
