// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['view', 'play', 'frame']

  show(e) {
    e.preventDefault()
    this.playTarget.classList.toggle('hidden')
    this.viewTarget.insertAdjacentHTML(
      'beforeend',
      `<iframe class="absolute pin w-full h-full transition-property-all transition-fast transition-timing-ease-in-out bg-black" height="270" width="480" src="${this.playTarget.getAttribute(
        'data-play-url'
      )}" scrolling="no" sandbox="allow-scripts allow-same-origin allow-presentation allow-popups" name="" frameborder="0" allowfullscreen="" allow="autoplay; fullscreen"></iframe>`
    )
  }
}
