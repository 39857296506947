/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// frontend/packs/application.js
// eslint-disable-next-line import/no-extraneous-dependencies
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'whatwg-fetch'
import '@stimulus/polyfills'

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
// app/javascript/initializers/rails_ujs.js
import Rails from 'rails-ujs'

import Flickity from 'flickity'

// Import our project's application stylesheet, which happens to contain our Tailwind stuff.
import '../stylesheets/application.css'
import '../components/index'

// Now within the views, you can call images using
// <%= image_tag(asset_pack_path('images/abc.svg')) %>

console.log('Hello World from Webpacker')

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// RailsUjs
console.log('Great news! Webpacker is working up to this point!')
Rails.start()

// // app/javascript/initializers/turbolinks.js
// import Turbolinks from 'turbolinks'
// Turbolinks.start()
// Turbolinks.setProgressBarDelay(50)

// StimulusJS
const application = Application.start()
const context = require.context('controllers', true, /\.js(\.erb)?$/)
application.load(definitionsFromContext(context))

// Load Images from webpacker
require.context('../images/', true, /\.(gif|jpg|png|svg)$/i)

require('flickity-imagesloaded')

// now use imagesLoaded and fullscreen
if (document.getElementById('carousel')) {
  // eslint-disable-next-line no-unused-vars
  const flkty = new Flickity('#carousel', {
    imagesLoaded: true,
    fullscreen: true,
    draggable: true,
    groupCells: true,
    wrapAround: false,
    freeScroll: true,
    contain: true,
    // disable previous & next buttons and dots
    prevNextButtons: true,
    pageDots: false,
  })
}

// Firebase Push
// Initialize Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyCsv30H_qXbwKu4ydAAUJ31okEZIoh2Q8k',
  authDomain: 'www.lyricsmint.com',
  projectId: 'lyricsmint-web',
  messagingSenderId: '1053224114554',
  appId: '1:1053224114554:web:d1462b9e7f26c15bee1ec3',
  measurementId: 'G-C5JY620KG3',
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

// [START get_messaging_object]
// Retrieve Firebase Messaging object.
const messaging = getMessaging(app)

// IDs of divs that display Instance ID token UI or request permission UI.
const permissionDivId = 'lyricsmint-popover-container'

function postData(url = ``, data = {}) {
  // Default options are marked with *
  return fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    // mode: 'cors', // no-cors, cors, *same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      // "Content-Type": "application/x-www-form-urlencoded",
    },
    // redirect: 'follow', // manual, *follow, error
    // referrer: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  }).then(response => response.json()) // parses response to JSON
}

function isTokenSentToServer() {
  return window.localStorage.getItem('sentToServer') === '1'
}

function setTokenSentToServer(sent) {
  window.localStorage.setItem('sentToServer', sent ? '1' : '0')
}

function showHideDiv(divId, show) {
  const div = document.querySelector(`#${divId}`)
  if (show) {
    div.style = 'display: visible'
  } else {
    div.style = 'display: none'
  }
}

// Clear the messages element of all children.
function clearMessages() {
  const messagesElement = document.querySelector('#messages')
  /* while (messagesElement.hasChildNodes()) {
	  messagesElement.removeChild(messagesElement.lastChild);
	} */
  messagesElement.style = 'display: none'
}

function updateUIForPushEnabled(currentToken) {
  console.log('current token: ', currentToken)
  showHideDiv(permissionDivId, false)
}

function updateUIForPushPermissionRequired() {
  setTimeout(() => {
    // showHideDiv(permissionDivId, true)
    // We're not directly displaying permission dialog of chrome browser
    // eslint-disable-next-line no-use-before-define
    requestPermission()
  }, 10000)
}

// Send the Instance ID token your application server, so that it can:
// - send messages back to this app
// - subscribe/unsubscribe the token from topics
function sendTokenToServer(currentToken) {
  if (!isTokenSentToServer()) {
    console.log('Sending token to server...')
    // TODO(developer): Send the current token to your server.
    const device = {
      token: currentToken,
      ua: navigator.userAgent,
    }
    postData('https://www.lyricsmint.com/devices', {
      device,
    })
      .then(data => console.log(JSON.stringify(data))) // JSON-string from `response.json()` call
      .catch(error => console.error(error))
    setTokenSentToServer(true)
  } else {
    console.log("Token already sent to server so won't send it again unless it changes")
  }
}

function resetUI() {
  clearMessages()
  // [START get_token]
  // Get Instance ID token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.

  getToken(messaging, {
    vapidKey:
      'BMSAVAHl-lgWn2szAIhWDHLqznnQR0xdQ98IZ4xIh0zA77A6kZhSu7RAPeXRLamvy5mCwbsE5M_ouTUl7fWXoZI',
  })
    .then(currentToken => {
      if (currentToken) {
        sendTokenToServer(currentToken)
        updateUIForPushEnabled(currentToken)
      } else {
        // Show permission request.
        console.log('No Instance ID token available. Request permission to generate one.')
        // Show permission UI.
        updateUIForPushPermissionRequired()
        setTokenSentToServer(false)
      }
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err)
      setTokenSentToServer(false)
    })
  // [END get_token]
}

// Add a message to the messages element.
function appendMessage(payload) {
  // Push Message
  const pushTitle = document.querySelector('#push-title')
  const pushMessage = document.querySelector('#push-message')
  const pushIcon = document.querySelector('#push-image')
  const pushLinks = document.querySelectorAll('.push-link')
  pushTitle.textContent = payload.notification.title
  pushMessage.textContent = payload.notification.body
  pushIcon.setAttribute('src', payload.notification.icon)
  pushLinks.forEach(link => {
    link.setAttribute('href', payload.notification.click_action)
  })

  if (payload.notification.click_action === 'https://www.lyricsmint.com/') {
    showHideDiv('push-actions', false)
  }
  showHideDiv('messages', true)
}

// [START receive_message]
// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker
// setMessageHandler` handler.
onMessage(messaging, payload => {
  console.log('Message received. ', payload)
  // [START_EXCLUDE]
  // Update the UI to include the received message.
  appendMessage(payload)
  // [END_EXCLUDE]
})
// [END receive_message]

function requestPermission() {
  console.log('Requesting permission...')
  showHideDiv(permissionDivId, false)

  // Let's check if the browser supports notifications
  if (!('Notification' in window)) {
    alert('This browser does not support desktop notification')
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === 'granted') {
    // If it's okay let's create a notification
    resetUI()
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(permission => {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        resetUI()
      }
    })
    // [START request_permission]
    Notification.requestPermission()
      .then(permission => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          console.log('Notification permission granted.')
          const notification = new Notification(
            'Lyricsmint: Push Notification Registration Successful!',
            {
              body: 'Thank you for push notification registration!',
              badge: 'https://www.lyricsmint.com/icon.png',
              icon: 'https://www.lyricsmint.com/icon.png',
            }
          )
          // In many cases once an app has been granted notification permission, it
          // should update its UI reflecting this.
          resetUI()
        }
      })
      .catch(err => {
        console.log('Unable to get permission to notify.', err)
      })
    // [END request_permission]
  }
}

requestPermission()

window.requestPermission = requestPermission
window.showHideDiv = showHideDiv
